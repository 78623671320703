<script>
import { satellitesDataType } from '@/utils/enums';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import set from 'lodash/set';
import Swal from 'sweetalert2';

export default {
  components: {
    Confirmation: () => import('@/components/Confirmation.vue'),
    Import: () => import('./Import.vue')
  },

  data() {
    return {
      satellitesDataType,
      search: '',
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['captured_at'],
        sortDesc: [true],
      },

      list: [],
      listCount: 0,
      headers: [
        { text: 'Cliente', value: 'customer.name', sortable: true },
        { text: 'Fazenda', value: 'farm.name', sortable: true },
        { text: 'Tipo de coleta', value: 'type', sortable: true },
        { text: 'Data de captura', value: 'captured_at', sortable: true },
        { text: 'Ações', value: '_actions', sortable: false, align: 'end' },
      ],
    };
  },

  watch: {
    search: debounce(function(){
      this.$set(this.options, 'page', 1)
      this.fetch()
    }, 500),

    options: {
      handler () {
        this.fetch()
      },

      deep: true,
    },
  },

  created() {
    this.fetch()
  },

  methods:{
    async remove(id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid! $date: timestamptz) {
              update_images_by_pk(
                pk_columns: { id: $id },
                _set: {
                  deleted_at: $date
                }
              ) {
                id
              }
            }
          `,
          variables: {
            id,
            date: new Date()
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Removido com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
        this.fetch()
      } catch (e) {
        console.error(e);
      }
    },

    async removeImportCode(importCode) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation delete_images ($importCode: uuid!) {
              delete_images(where: { import_code: { _eq: $importCode } }){
                affected_rows
              }
            }
          `,
          variables: {
            importCode
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Removido importação com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
        this.fetch()
      } catch (e) {
        console.error(e);
      }
    },

    async fetch(){
      this.loading = true
      try {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        const filter = {
          limit: itemsPerPage,
          offset: (page - 1) * itemsPerPage,
          sortBy: sortBy.map((item, index) => {
            let sort = {}
            set(sort, 'farm', {customer: {name: 'asc'}, name: 'asc'});
            set(sort, item, sortDesc[index] ? 'desc' : 'asc');
            return sort
          })
        }
        if(this.search) {
          filter.where = {}
          set(filter.where, '_or[0].farm.name', { _ilike: `%${this.search}%` })
          set(filter.where, '_or[1].customer.name', { _ilike: `%${this.search}%` })
        }

        const res = await this.$apollo.query({
          query: gql`
            query images($limit: Int, $offset: Int, $where: images_bool_exp, $sortBy: [images_order_by!] ) {
              list: images(limit: $limit, offset: $offset, where: $where, order_by: $sortBy){
                id
                customer {
                  name
                }
                farm {
                  name
                }
                type
                captured_at
                import_code
              }
              listCount: images_aggregate(where: $where){
                aggregate{
                  count
                }
              }
            }
          `,

          variables: {
            ...filter
          },

          fetchPolicy: 'no-cache',
        })
        this.list = res.data.list
        this.listCount = res.data.listCount.aggregate.count
      } catch(e) {
        console.error(e)
      }
      this.loading = false
    }
  }
};
</script>

<template>
  <div>
    <h1 class="mb-5">Imagens</h1>

    <v-card>
      <v-data-table
        must-sort
        :headers="headers"
        :items="list"
        :options.sync="options"
        :server-items-length="listCount"
        :loading="loading"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Procurar por fazenda ou cliente"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn color="primary" dark class="mr-2" @click="$refs.modalImport.$emit('create')"> importar </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ satellitesDataType[item.type] }}
        </template>
        <template v-slot:[`item.captured_at`]="{ item }">
          {{ new Date(item.captured_at) | dateFormat('DD/MM/YYYY') }}
        </template>
        <template v-slot:[`item.info_lost`]="{ item }">
          {{ item.info_lost | percent(1) }}
        </template>
        <template v-slot:[`item.visible_ms`]="{ item }">
          {{ item.visible_ms | number:decimals(0) }}
        </template>
        <template v-slot:[`item._actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <confirmation message="Tem certeza que deseja remover esta medição?" @success="() => remove(item.id)">
                <v-list-item>
                  <v-list-item-title>Remover{{ item.title }}</v-list-item-title>
                </v-list-item>
              </confirmation>
              <confirmation v-if="item.import_code" message="Tem certeza que deseja remover esta importação?" @success="() => removeImportCode(item.import_code)">
                <v-list-item>
                  <v-list-item-title>Remover importação{{ item.title }}</v-list-item-title>
                </v-list-item>
              </confirmation>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <import ref="modalImport" @success="fetch()" />
  </div>
</template>
